import React from "react";
import { Redirect, Route } from "react-router-dom";

import AdminAuthService from "../services/Admin/AuthService";
import AuthService from "../services/User/AuthService";

const AuthProtected = (props) => {
  // const [isLoggedIn, setIsLoggedIn] = React.useState();

  let isAuth = AuthService.isLoggedIn();
  // if (isAuth) {
  //   setIsLoggedIn(true);
  // }

  // const isAdminAuth = AdminAuthService.isLoggedIn();
  // if (isAdminAuth) {
  //   setIsLoggedIn(true);
  // }

  // if (isAuth) {
  //   return (
  //     <Redirect
  //       to={{ pathname: "/user/dashboard", state: { from: props.location } }}
  //     />
  //   );
  // }

  // if (isAdminAuth) {
  //   return (
  //     <Redirect
  //       to={{ pathname: "/admin/dashboard", state: { from: props.location } }}
  //     />
  //   );
  // }

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const authorization = params.get("authorization");

  if (authorization) {
    isAuth = true;
  }

  if (!isAuth) {
    return (
      <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

const AdminAuthProtected = (props) => {
  const isAdminAuth = AdminAuthService.isLoggedIn();

  if (!isAdminAuth) {
    return (
      <Redirect
        to={{ pathname: "/admin_login", state: { from: props.location } }}
      />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AdminAuthProtected, AccessRoute };
